import React from "react";

function ServerManagement() {
  return (
    <div className="ServerManagement">
      <h2>Personal Projects</h2>
      <div>
        <div>
          <h3>Home Assistant</h3>
          <img src="/Images/home-assistant.png" alt="The logo for Home Assistant." />
          <p>
            A lot of time has been put into this system to automate most home
            activities. This includes automating the lighting, the storage and
            output of surplus solar power and controlling anything locally over
            WiFi or ZigBee.
          </p>
          <p>
            <strong>Home Assistant</strong> is really an amazing, simple enough
            tool for making the best use of smart devices!
          </p>
        </div>
        <div>
          <h3>Home NAS</h3>
          <img src="/Images/openmediavault.png" alt="The logo for OpenMediaVault." />
          <p>
            The Network Attached Storage (<strong>NAS</strong>) uses OpenMediaVault to share
            documents over the local network. I can still access this securely
            over a VPN when I am not at home.
          </p>
        </div>
        <div>
          <h3>Web Hosting</h3>
          <img src="/Images/web-hosting-icon.png" alt="A laptop in a circle, representing the web." />
          <p>I use an <strong>Intel NUC</strong> to host my websites. For simple static sites, this allows me to easily deploy a site without the unnecessary costs of an external server.</p>
        </div>
      </div>
    </div>
  );
}

export default ServerManagement;
